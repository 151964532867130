import React, {useEffect} from 'react';
import styled from 'styled-components';
import Carousel from 'nuka-carousel';
import useDimension from '../../../src/hooks/use-dimension';
import {Button} from '../../../src/Widgets';
import StaticImage from '../../../src/Components/StaticImage';
import Slider from 'react-slick';
import {navigate} from 'gatsby';
const appConfig = require('../../../src/data.json');

const Indicator = styled.ul`
  bottom: -40px;

  & > li {
    margin: 5px;
    cursor: pointer;
    background-color: #a1a1a6;
    width: 55px;
    height: 8px;
  }

  & > li.slick-active {
    background-color: ${appConfig.colors.second};
  }

  & > li > div {
    width: 100%;
    height: 100%;
  }
`;

function Item({item}) {
  return (
    <ItemWrapper>
      <StaticImage filename={item.image} ratio="1" alt={item.title} />
      <div className="content">
        <h3 className="title">{item.title}</h3>
        <p>{item.detail}</p>
        <Button
          className="button hover-reverse-btn"
          onClick={() => navigate('/products')}>
          See More
        </Button>
      </div>
    </ItemWrapper>
  );
}

const ItemWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  box-shadow: 4px 5px 8px 0px #bbb;

  & > .content {
    padding: 25px;

    & > .title {
      color: ${appConfig.colors.main};
      font-size: 21px;
      font-weight: border;
      margin-bottom: 20px;
    }

    & > p {
      margin-bottom: 20px;
      height: 95px;
    }
  }
`;

export default function ServiceCarousel(props) {
  const {items} = props;
  const {dimension} = useDimension();

  const {rwd} = useDimension();

  const getSlideWidth = (rwd) => {
    if (rwd === 'mobile') {
      return 1;
    } else if (rwd === 'pad') {
      return 2;
    }
    return 3;
  };

  const slidesToShow = getSlideWidth(rwd);

  return (
    <Wrapper>
      <Slider
        dots={true}
        speed={500}
        autoplay={true}
        autoplaySpeed={6000}
        slidesToShow={slidesToShow}
        slidesToScroll={slidesToShow}
        adaptiveHeight={true}
        appendDots={(dots) => <Indicator>{dots}</Indicator>}
        customPaging={(i) => {
          return <div />;
        }}>
        {items.map((item, idx) => (
          <Item item={item} key={idx} />
        ))}
      </Slider>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;

  & .slick-list {
    margin: 0 -10px;
    & .slick-slide > div {
      padding: 0 10px;
      margin-bottom: 10px;
    }
  }
`;
