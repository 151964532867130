import React, {useEffect} from 'react';
import styled from 'styled-components';
import Carousel from 'nuka-carousel';
import useDimension from '../../../src/hooks/use-dimension';
import StaticImage from '../../../src/Components/StaticImage';
const appConfig = require('../../../src/data.json');

function Indicator(props) {
  let {currentSlide, goToSlide, slideCount} = props;
  let indexes = Array.from(Array(slideCount).keys());
  return (
    <IndicatorStyle.Wrapper>
      {indexes.map((index) => (
        <IndicatorStyle.Dot
          key={index}
          selected={index === currentSlide}
          onClick={() => goToSlide(index)}
        />
      ))}
    </IndicatorStyle.Wrapper>
  );
}

const IndicatorStyle = {
  Wrapper: styled.div`
    display: flex;
    align-items: center;
  `,
  Dot: styled.div`
    cursor: pointer;
    background-color: ${(props) =>
      props.selected ? appConfig.colors.second : '#a1a1a6'};
    width: 55px;
    height: 8px;
    margin: 5px;

    @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
      width: 20px;
      height: 4px;
      margin: 3px;
    }
  `,
};

function Item({item, title}) {
  return (
    <ItemWrapper>
      <div className="image">
        <StaticImage
          filename={item.image}
          alt={item.title}
          extraStyle={{maxWidth: 500, maxHeight: 500}}
        />
      </div>

      <div className="content">
        <h1>{title}</h1>
        <div className="title">{item.title}</div>
      </div>
    </ItemWrapper>
  );
}

const ItemWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  & > .image {
    flex: 1 0 auto;
    display: flex;
    justify-content: center;
  }

  & > .content {
    flex: 1;
    padding: 30px 0;

    & > h1 {
      color: ${appConfig.colors.second};
      margin-bottom: 30px;
    }

    & > .title {
      color: ${appConfig.colors.text};
      font-size: 26px;
      font-weight: border;
      margin-bottom: 20px;
    }
  }

  @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
    flex-direction: column;
    & > .content {
      height: 350px;
      padding: 40px 0;

      & > h1 {
        font-size: 24px;
        margin-bottom: 15px;
      }

      & > .title {
        font-size: 18px;
      }
    }
  }
`;

export default function DeviceCarousel(props) {
  const {items, title} = props;

  const {rwd} = useDimension();

  return (
    <Wrapper>
      <Carousel
        width={'100%'}
        height={'100%'}
        transitionMode="fade"
        renderBottomRightControls={(props) =>
          rwd === 'desktop' && <Indicator {...props} />
        }
        renderBottomCenterControls={(props) =>
          rwd !== 'desktop' && <Indicator {...props} />
        }
        renderCenterLeftControls={() => false}
        renderCenterRightControls={() => false}
        getControlsContainerStyles={(key) => {
          if (key === 'BottomRight') {
            return {
              bottom: 40,
              right: '25%',
              transform: 'translateX(50%)',
            };
          }
          if (key === 'BottomCenter') {
            return {
              bottom: 30,
            };
          }
        }}
        autoplay={true}
        autoplayInterval={6000}>
        {items.map((item, idx) => (
          <Item item={item} key={idx} title={title} />
        ))}
      </Carousel>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
