import React, {useContext, useState} from 'react';
import {Context} from '../../../src/AppContext';
import {Link, navigate} from 'gatsby';
import {Input, message} from 'antd';
import styled from 'styled-components';
import StaticImage from '../../../src/Components/StaticImage';
import {Button as WidgetButton} from '../../../src/Widgets';
import ServiceCarousel from './ServiceCarousel.B';
import DeviceCarousel from './DeviceCarousel';
const appConfig = require('../../../src/data.json');

const MAX_WIDTH = appConfig.maxContentWidth;
const Metadata = {
  title: '關於印刷曙叔',
  detail:
    '曙叔從事印刷至今將近二十年，看見了印刷產業的巨變，從以前的大量生產到現在的少量多樣化，從過往的紙本化到現代的電子化，印刷產業現在慢慢的被貼上夕陽產業的標籤，希望能重見印刷產業的曙光！我們決定於2022年成立印刷電商平台，透過不同的管道將印刷產業重新帶到大家的生活中，讓大家都能體驗到創作屬於自己的印刷品的樂趣。',
  button: {
    label: '全部商品',
    link: '/products',
  },
  image: 'about-logo.png',

  service_section: {
    title: '服務項目',
    items: [
      {
        title: '公版的印刷品',
        detail:
          '提供已經制定好的版型及常用紙材，給想要精簡預算的您，同樣能創造出優質的印刷品。',
        image: 'about-service-01.jpeg',
      },
      {
        title: '獨立開版的各式各樣印刷品',
        detail:
          '給想要與眾不同的您，印刷曙叔可以配合您各式印刷品的需求，獨立開版製作呈現出與別人不一樣的獨特印刷品。',
        image: 'about-service-02.jpeg',
      },
      {
        title: '客製化的禮贈品',
        detail:
          '想要送禮卻沒有想法嗎? 想做與眾不同的東西卻沒有idea嗎? 歡迎來印刷曙叔尋找適合您的禮品吧!',
        image: 'about-service-03.jpeg',
      },
      {
        title: '少量數位書籍',
        detail:
          '不論是騎馬釘、膠裝、穿線平裝、穿線精裝、裸背穿線裝、古書裝、雙線圈裝等，各類您想的到裝訂方式的都可以為您印刷裝訂成冊。',
        image: 'about-service-04.jpeg',
      },
      {
        title: '製版、印刷、直噴等代工服務',
        detail:
          '如果您是同行或相關產業客戶，僅需要印刷曙熟提供某環節的作業，請來信與我們聯絡，我們也都很樂意配合各式代工服務喔！',
        image: 'about-service-05.jpeg',
      },
    ],
  },

  feature_section: {
    title: '服務特色',
    items: [
      {
        title: '系統化的一條龍服務',
        detail:
          '從傳檔、付款、製作、出貨，所有的過程在網站都可一次完成，下單流程輕鬆方便。',
        image: '/images/home-service-01.png',
      },
      {
        title: '專業親切的線上諮詢',
        detail:
          '曙叔的客服皆經過教育訓練，對審稿及製作細節皆能提供客戶專業的諮詢服務。',
        image: '/images/home-service-02.png',
      },
      {
        title: '商品品質保證',
        detail:
          '曙叔的客製化商品皆獨一無二，產品經過層層的品質把關，售後服務由專人協助立刻處理。',
        image: '/images/home-service-03.png',
      },
      {
        title: '多元的付款方式',
        detail:
          '提供多元的付款方式：信用卡、ATM轉帳、超商代碼繳費，預儲加值、企業會員月結付款等。',
        image: '/images/home-service-04.png',
      },
    ],
  },

  device_section: {
    title: '印刷設備介紹',

    items: [
      {
        title: 'UF-R1610 Xaar 平台式 UV 直噴噴墨機',
        detail: '',
        image: 'about-device-01.jpeg',
      },
      {
        title: 'Fuji Xerox V180 數位印刷機',
        detail: '',
        image: 'about-device-02.jpeg',
      },
      {
        title: '海德堡 SM-52五開印刷機',
        detail: '',
        image: 'about-device-03.jpeg',
      },
      {
        title: 'TRENDSETTER Q800直接製版機',
        detail: '',
        image: 'about-device-04.jpeg',
      },
      {
        title: 'FUJI-F9000 底片輸出機',
        detail: '',
        image: 'about-device-05.jpeg',
      },
      {
        title: 'GREAT 52A 無線膠裝機',
        detail: '',
        image: 'about-device-06.jpeg',
      },
    ],
  },

  contact_section: {
    title: '聯絡資訊',
    // remember to replace iframe's width and height to 100%
    map_html: '',
    // '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3615.0028901837118!2d121.56235021455373!3d25.033975983972372!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442abb6da9c9e1f%3A0x1206bcf082fd10a6!2zMTEw5Y-w5YyX5biC5L-h576p5Y2A5L-h576p6Lev5LqU5q61N-iZnw!5e0!3m2!1szh-TW!2stw!4v1591247827212!5m2!1szh-TW!2stw" width="100%" height="100%" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>',
    infos: [
      {
        title: '客服電話',
        detail: '(02)2226-6513',
      },
      {
        title: '電子信箱',
        detail: 'service@uncleprint.com.tw',
      },
      {
        title: '客服資訊',
        detail: '周一至周五 9:00~18:00',
      },
      {
        title: 'Facebook 粉絲專頁',
        detail: '印刷曙叔 Uncle Print',
      },
      {
        title: 'IG ID',
        detail: '@uncle_print',
      },
    ],
  },

  form_section: {
    title: '聯絡我們',
    detail:
      '對於我們有任何問題與報價需求，歡迎填寫表單與我們聯絡！\r\n也可直接來信至： service@uncleprint.com.tw',
  },
};

const CustomerFormFields = [
  {
    name: 'name',
    placeholder: '您的姓名',
    required: true,
  },
  {
    name: 'phone',
    placeholder: '手機號碼',
    required: true,
  },
  {
    name: 'tel',
    placeholder: '市話',
    required: false,
  },
  {
    name: 'telExtension',
    placeholder: '分機號碼',
    required: false,
  },
  {
    name: 'email',
    placeholder: '電子信箱',
    required: true,
    validator: (value) => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(value.toLowerCase());
    },
  },
];

const EmailFormFields = [
  {
    name: 'subject',
    placeholder: '信件主旨',
    required: true,
  },
  {
    name: 'content',
    placeholder: '信件內容',
    required: true,
  },
];

class ErrAboutPage extends Error {}

const Button = ({children, ...props}) => (
  <WidgetButton className="button hover-reverse-btn" {...props}>
    {children}
  </WidgetButton>
);

export default function AboutPage(props) {
  const [data, setData] = useState({
    name: '',
    phone: '',
    tel: '',
    telExtension: '',
    email: '',
    title: '',
    content: '',
  });

  const app = useContext(Context);

  const onFormSubmit = async () => {
    try {
      // basic validating form data
      let _blankFields = [];
      let _invalidFields = [];
      for (let f of [...CustomerFormFields, ...EmailFormFields]) {
        if (!data[f.name] && f.required) {
          _blankFields.push(f.placeholder);
        } else if (f.validator && !f.validator(data[f.name])) {
          _invalidFields.push(f.placeholder);
        }
      }
      if (_blankFields.length !== 0) {
        throw new ErrAboutPage(`請填入欄位：${_blankFields.join('、')}`);
      } else if (_invalidFields.length !== 0) {
        throw new ErrAboutPage(
          `請確認欄位：${_invalidFields.join('、')}的格式`,
        );
      }

      let formData = {
        subject: data.subject,
        message: data.content,
        name: data.name,
        phone: data.phone,
        local_phone: `${data.tel}#${data.telExtension}`,
        email: data.email,
      };

      await app.actions.contact(formData);

      message.success('聯絡表單已送出！');
    } catch (err) {
      if (err instanceof ErrAboutPage) {
        message.warning(err.message);
      } else {
        message.error('聯絡表單送出失敗！');
      }
    }
  };

  const onInputChange = (field) => (e) => {
    let value = e.target.value;
    setData((pre) => ({...pre, [field]: value}));
  };

  return (
    <Wrapper>
      <div className="top-section ">
        <div className="background" />
        <div className="constraint">
          <StaticImage
            filename={Metadata.image}
            alt="logo"
            extraStyle={{maxWidth: 450, margin: '0 auto 40px auto'}}
          />
          <div className="content ">
            <h2>{Metadata.title}</h2>
            <p>{Metadata.detail}</p>
            <Button onClick={() => navigate(Metadata.button.link)}>
              {Metadata.button.label}
            </Button>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="section-context constraint ">
          <h2>{Metadata.service_section.title}</h2>
          <div className="service-section">
            <ServiceCarousel items={Metadata.service_section.items} />
          </div>
        </div>
      </div>

      <div className="section yellow">
        <div className="section-context constraint ">
          <div className="device-section">
            <DeviceCarousel
              items={Metadata.device_section.items}
              title={Metadata.device_section.title}
            />
          </div>
        </div>
      </div>

      <div className="section">
        <div className="section-context constraint feature-section">
          <h2>{Metadata.feature_section.title}</h2>
          <div className="feature-wrapper">
            {Metadata.feature_section.items.map((item, idx) => (
              <div key={idx} className="feature-item">
                <img
                  src={item.image}
                  height="100"
                  width="100"
                  alt="services-icon"
                />
                <h3>{item.title}</h3>
                <p>{item.detail}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="section grey">
        <div className="info-section constraint">
          <div className="info-details">
            <h1>{Metadata.contact_section.title}</h1>
            {Metadata.contact_section.infos.map((item, idx) => (
              <p key={idx}>
                {item.title}：{item.detail}
              </p>
            ))}
          </div>

          {/* <div className="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3616.0285157833423!2d121.48615731500561!3d24.99914698398869!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34680281aa9dcad7%3A0x7ba6cd51addeadd6!2z5a625piM5b2p6Imy5Y2w5Yi36KO954mI5pyJ6ZmQ5YWs5Y-4!5e0!3m2!1szh-TW!2stw!4v1629093602829!5m2!1szh-TW!2stw"
              width="100%"
              height="100%"
              frameborder="0"
              allowfullscreen=""
              loading="lazy"></iframe>
          </div> */}
        </div>
      </div>

      <div className="section">
        <div className="section-context constraint form-section">
          <h2>{Metadata.form_section.title}</h2>
          <p>{Metadata.form_section.detail}</p>
          <div className="form-wrapper">
            <div className="form">
              {CustomerFormFields.map((field, idx) => {
                return (
                  <Input
                    key={idx}
                    value={data[field.name]}
                    onChange={onInputChange(field.name)}
                    placeholder={field.placeholder}
                  />
                );
              })}
            </div>
            <div className="form">
              {EmailFormFields.map((field, idx) => {
                if (field.name === 'content') {
                  return (
                    <Input.TextArea
                      key={idx}
                      // style={{ flex: 1 }}
                      value={data[field.name]}
                      placeholder={field.placeholder}
                      onChange={onInputChange(field.name)}
                    />
                  );
                }
                return (
                  <Input
                    key={idx}
                    value={data[field.name]}
                    onChange={onInputChange(field.name)}
                    placeholder={field.placeholder}
                  />
                );
              })}
            </div>
          </div>

          <Button onClick={onFormSubmit}>確認送出</Button>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #fff;
  flex: 1;

  & .constraint {
    max-width: ${MAX_WIDTH + 40}px;
    margin: 0 auto;
    padding: 0px 20px;
  }

  & .top-section {
    position: relative;
    z-index: 0;
    text-align: center;
    margin-bottom: 30px;
    padding-top: 60px;

    & > .background {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      background-color: ${appConfig.colors.second};
      height: 400px;
      z-index: -1;
    }

    & img {
      width: 100%;
      height: auto;
    }

    & .content {
      box-shadow: 2px 2px 9px -2px #999;
      background-color: white;
      padding: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 1;

      & > h2 {
        font-size: 21px;
        color: ${appConfig.colors.main};
        margin-bottom: 30px;
      }

      & > p {
        color: ${appConfig.colors.textSecond};
        margin-bottom: 30px;
        max-width: 742px;
        font-size: 16px;
        line-height: 1.8;
      }
    }
  }

  & > .section {
    padding: 40px 0px;
    margin-bottom: 30px;

    & > .section-context {
      text-align: center;
      & > h2 {
        color: ${appConfig.colors.second};
        font-size: 21px;
        font-weight: 400;
        border-bottom: 1px solid ${appConfig.colors.second};
        padding: 7px 32px;
        display: inline-block;
        margin-bottom: 40px;
      }

      & > h3 {
        color: #5a5a5a;
        font-size: 24px;
        margin-bottom: 14px;
      }

      & p {
        color: #717071;
        white-space: pre-wrap;
        font-size: 16px;
        line-height: 1.8;
      }

      @media screen and (max-width: 600px) {
        padding-right: 15px;
        padding-left: 15px;
      }
    }

    & .service-section {
      margin-bottom: 30px;
    }

    & .device-section {
      background-color: white;
    }

    & .feature-section {
      & > .feature-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        & > .feature-item {
          flex-basis: 25%;
          text-align: center;
          padding: 30px;

          & > img {
            margin-bottom: 20px;
          }

          & > h3 {
            color: ${appConfig.colors.main};
            margin-bottom: 20px;
            font-size: 21px;
          }
        }
      }

      @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
        & .feature-item {
          flex-basis: 50%;
        }
      }
      @media screen and (max-width: ${appConfig.breakpoints.sm}px) {
        & .feature-item {
          flex-basis: 100%;
          & > p {
            display: none;
          }
        }
      }
    }

    & .info-section {
      display: flex;
      & > .info-details {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & > h1 {
          color: ${appConfig.colors.second};
        }
        & > p {
          color: ${appConfig.colors.text};
          font-size: 16px;
          line-height: 1.8;
        }
      }

      & > .map {
        flex: 1;
        margin: -40px 0;
      }

      @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
        flex-direction: column;
        & > .info-details {
          margin-bottom: 15px;
          & > p {
            margin-bottom: 5px;
          }
        }

        & > .map {
          flex: 1 0 150px;
          margin: 0 -20px -40px -20px;
        }
      }
    }

    & .form-section {
      & .form-wrapper {
        margin-top: 40px;
        margin-bottom: 35px;
        display: flex;
        & .form {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          & input,
          & textarea {
            border: 1px solid #cccccc;
            padding: 10px 20px;
            color: #5a5a5a;
            border-radius: 5px;
            margin-bottom: 20px;
            font-size: 16px;

            &:focus {
              outline: none;
            }
          }

          & textarea {
            flex: 1;
            resize: none;
          }

          &:last-child {
            margin-left: 40px;
          }
        }

        @media screen and (max-width: 600px) {
          flex-direction: column;

          & .form {
            &:last-child {
              margin-left: 0px;
            }
            & input,
            & textarea {
              margin-bottom: 10px;
            }
          }
        }
      }

      & .error-hint {
        color: tomato;
        font-size: 12px;
        margin-top: 10px;
      }

      & .success-hint {
        color: #28a689;
        font-size: 12px;
        margin-top: 10px;
      }
    }
  }

  & > .section.grey {
    background-color: #f6f6f6;
  }

  & > .section.yellow {
    background-color: ${appConfig.colors.third};
  }

  & .button {
    background-color: ${appConfig.colors.second};
    border: 0;
    border-radius: 5px;

    &.hover-reverse-btn:hover {
      background-color: white;
      color: ${appConfig.colors.main};
    }
  }
`;
